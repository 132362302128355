import { NetworkGroup } from './system'
export interface AlarmType {
    ID: string
    Descrizione: string
    IdCode: string
    nomeAllarme: string
    TipoMacchinaID: string
    TipoMacchinaDesc: string
}

export interface DeviceDetail {
    idCommunicationProtocol: number
    idNetwork: number
    idDeviceModel: number
    idMaster: number | null
    groups: number[]
    idEditor: number
}

export interface Device extends Omit<DeviceDetail, 'groups'> {
    ID: number
    code: string
    name: string
    note: string
    serialNo: string
    snmpPort: number
    ipAddress: string
    phoneNumber: string | null
    coordinates: {
        latitude: number
        longitude: number
    }
    deviceInfo: {
        schema: string
    }
    currentState: number
    automaticCheck: number
    maintenanceMode: number
    snmpCommunityRead: string
    snmpCommunityWrite: string
    children?: DeviceEvent[]
    deviceModel: DeviceModel
    master: Device | null
    snmpVersion: string | null
    groups: NetworkGroup[]
}

export interface DeviceToEdit extends DeviceDetail {
    ID: number
    code: string
    name: string
    note: string
    serialNo: string
    snmpPort: string
    ipAddress: string
    phoneNumber: string
    coordinates: {
        latitude: string
        longitude: string
    }
    deviceInfo: any
    currentState?: number
    automaticCheck: number
    maintenanceMode: number
    snmpCommunityRead: string
    snmpCommunityWrite: string
    master: Device | null
    snmpVersion: string | null

}

export interface DeviceEvent {
    events: number
    openedEvents: OpenedEvent[]
    children?: DeviceEvent[]
    device: DeviceNodeEvent
}

export interface OpenedEvent {
    Id: number
    idDevice: number
    dateStart: string
    dateEnd: string
    variable: string
    startValue: string
    endValue: string
    operator: string
    referenceValue: string
    notificationSent: boolean
    hide: boolean
    forcedCloseBy: number
}

export interface DetailedEvent extends OpenedEvent {
    device: DeviceNodeEvent
}

export interface DeviceNodeEvent {
    ID: number
    id: number
    automaticCheck: number
    code: string
    coordinates: { latitude: number; longitude: number }
    currentState: number
    deviceInfo: { schema: string }
    deviceModel: DeviceModel
    brand: { name: string }
    idMaster: number
    ipAddress: string
    maintenanceMode: number
    name: string
    note: string
    serialNo: string
    snmpCommunityRead: string
    snmpCommunityWrite: string
    snmpPort: number
}

export interface DeviceModel {
    ID: number
    availableCommands: DeviceAvailableCommand[]
    brand?: DeviceBrand
    defaultVars: DeviceDefaultVars[]
    deviceType?: DeviceType
    idBrand: number
    idDeviceType: number
    model: string
    mib: string
    idDashboard: number
    modelSchema: ModelSchema[]
}

export interface DeviceBrand {
    name: string
    logo: string
    description: string
}

export interface DeviceType {
    ID: number
    note: string
    name: string
    description: string
}

export enum ThresholdRule {
    lower = 'lower',
    higher = 'higher',
    notEqual = 'not equal',
}
export enum ThresholdLevel {
    low = 0,
    medium = 1,
    high = 2,
}
export enum ThresholdType {
    absolute = 'absolute',
    percentage = 'percentage',
}

export interface Threshold {
    id: string
    value: number
    absolute_value: number
    rule: ThresholdRule
    level: ThresholdLevel
    type: ThresholdType
    enabled: boolean
    event_string?: string
    eventString?: string
    delay?: number
}

export interface DeviceDefaultVars {
    oid?: string
    name: string
    valMax: string
    valMin: string
    description: string
    eventEnabled: boolean
    alarmLevelMax: number
    alarmLevelMin: number
    unitOfMeasure: string | null
    infoReport: number
    conversionValue: number | string
    ID?: number
    thresholds: Threshold[] | null
    customName: string | null
    modelSchemaRef: string | null
    valueMap?: string
    writeCommand?: string
}

export interface DeviceAvailableCommand {
    oid?: string
    name: string
    command: string
    value?: string
    requiredGrant: number
    valueMap?: string
    userDefined?: number
    userMessage?: string
}

export interface ModelSchema {
    name: string
    type: string
    value: string
    required: boolean
    max_length: number
}

export interface Scheduler {
    ID: number
    idUser: number
    idDevice: number | null
    command: string
    dateFrom: string
    dateTo: string
    enabled: number
    sendNotification: number
    creationDate: string
    lastExecutionDate: null | string
    nextExecutionDate: null | string
    schedule: string
    idNetworkGroup: null | number
    idEditor: null | number
    device?: Device
    networkGroup?: NetworkGroup
}
