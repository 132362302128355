import React, { useCallback, useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { ReducerData, Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { ROUTE_NAMES } from '../../utils/routes'
import { useNavigate } from 'react-router-dom'
import CustomAxios from '../../customComponents/customAxios'
import AlertModal from '../Modals/AlertModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faMinusCircle, faPen, faToolbox } from '@fortawesome/free-solid-svg-icons'
import { useTablePagination } from '../../utils/hooks/common'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { Chip, Tooltip } from '@material-ui/core'
import { statusLabelForDevice, statusPinStyleForDevice } from '../../utils/functions'
import { COLORS } from '../../styles/constants'

interface Props {
    filterParams?: any
    reloads?: boolean
    onFinish?: () => void
    onDeviceLimitReached?: (active: boolean) => void
}

const RemoteControlsTable: React.FC<Props> = (props) => {
    const { currentNetwork } = useSelector<Reducers, ReducerData>((store) => store.data)

    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const { configurations } = useSelector<Reducers, ReducerData>((state) => state.data)
    const [selectedElementToRemove, setSelectedElementToRemove] = useState(-1)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [firstDataLoad, setFirstDataLoad] = useState(true)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const tableRef = React.useRef<any>()
    const [devicesLimit, setDevicesLimit] = useState(0)

    const navigate = useNavigate()
    const { t } = useTranslation()
    const deleteMessage = t('systemMessages.removeElementFromList')
    const dispatch = useDispatch()

    const currentPageFromURL = useTablePagination('page')

    useEffect(() => {
        handlePageDataUpdate()
    }, [])

    useEffect(() => {
        configurations.map((param) => {
            if (param.key === 'device_limit' && parseInt(param.value, 10) > 0) {
                setDevicesLimit(parseInt(param.value, 10))
            }
            return
        })
    }, [configurations])

    useEffect(() => {
        if (devicesLimit > 0) {
            props.onDeviceLimitReached(devicesLimit <= totalElements)
        }
    }, [totalElements, devicesLimit])

    const handlePageDataUpdate = useCallback(() => {
        const currentPageValue = currentPageFromURL > 0 ? currentPageFromURL : 0
        if (currentPageValue * pageSize <= totalElements) {
            setCurrentPage(currentPageValue)
            resetData(currentPageValue)
        }
    }, [currentPageFromURL, totalElements, pageSize])

    const handleDataCount = (totCounter: number): number => {
        setTotalElements(totCounter)
        return totCounter
    }

    const columns = [
        {
            title: t('fields.status'),
            field: 'current_state',
            render: (rowData: any) => {
                return (
                    <div className="small-cell">
                        {rowData.maintenanceMode ? (
                            <div className="dot-container">
                                <div style={{ minWidth: '102px' }} className="dot-tooltip">
                                    {t('fields.onMaintenanceMode')}
                                </div>
                                <FontAwesomeIcon
                                    icon={faToolbox}
                                    color={COLORS.palette.darkBlue}
                                    style={{ width: '20px', height: '20px', marginLeft: '7px' }}
                                />
                            </div>
                        ) : (
                            <div className="dot-container">
                                <div className="dot-tooltip">{t(statusLabelForDevice(rowData))}</div>
                                <span className={statusPinStyleForDevice(rowData)} />
                            </div>
                        )}
                    </div>
                )
            },
        },
        {
            title: t('fields.name'),
            field: 'name',
            width: '10%',
            render: (rowData: any) => (
                <a className="colorRed table-link-value" href={`/${ROUTE_NAMES.DEVICE}/${rowData.ID}`}>
                    {rowData.name}
                </a>
            ),
        },
        {
            title: t('fields.brand'),
            field: 'brand',
            render: (rowData: any) => {
                return (
                    <>
                        {rowData.deviceModel && rowData.deviceModel.brand.ID !== null && (
                            <>
                                {user.idSysGrant === 0 ? (
                                    <span
                                        className="colorRed table-link-value"
                                        onClick={() =>
                                            navigate(`/${ROUTE_NAMES.BRANDS}/${rowData.deviceModel.brand.ID}`)
                                        }
                                    >
                                        {rowData.deviceModel.brand.name}
                                    </span>
                                ) : (
                                    <span>{rowData.deviceModel.brand.name}</span>
                                )}
                            </>
                        )}
                    </>
                )
            },
        },
        {
            title: t('fields.model'),
            field: 'model',
            render: (rowData: any) => {
                return (
                    <>
                        {rowData.deviceModel && rowData.deviceModel.ID !== null && (
                            <>
                                {user.idSysGrant === 0 ? (
                                    <span
                                        className="colorRed table-link-value"
                                        onClick={() => navigate(`/${ROUTE_NAMES.MODELS}/${rowData.deviceModel.ID}`)}
                                    >
                                        {rowData.deviceModel.model}
                                    </span>
                                ) : (
                                    <span>{rowData.deviceModel.model}</span>
                                )}
                            </>
                        )}
                    </>
                )
            },
        },
        // {
        //     title: t('fields.master'),
        //     field: 'master',
        //     render: (rowData: any) => {
        //         return (
        //             <>
        //                 {rowData.master !== null ? (
        //                     <a
        //                         className="colorRed table-link-value"
        //                         // onClick={() => navigate(`/${ROUTE_NAMES.DEVICE}/${rowData.ID}`, { state: { isView: true } })}
        //                         href={`/${ROUTE_NAMES.DEVICE}/${rowData.master.ID}`}
        //                     >
        //                         {rowData.master.name}
        //                     </a>
        //                 ) : (
        //                     <> </>
        //                 )}
        //             </>
        //         )
        //     }
        // },
        {
            title: t('fields.communicationProtocol'),
            field: 'id_communication_protocol',
            render: (rowData: any) => {
                return (
                    <>
                        {rowData.idCommunicationProtocol === 3 ? (
                            <span>
                                {t('fields.snmp')} - {rowData.ipAddress}:{rowData.snmpPort}
                            </span>
                        ) : rowData.idCommunicationProtocol === 2 ? (
                            <span>
                                {t('fields.sms')} - {rowData.phoneNumber}
                            </span>
                        ) : null}
                    </>
                )
            },
        },
    ]

    const actions = [
        (rowData: any) => ({
            icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
            onClick: () => navigate(`/${ROUTE_NAMES.DEVICE}/${rowData.ID}`, { state: { isView: false } }),
            tooltip: t('common.edit'),
        }),
        user.idSysGrant === 0 &&
            ((rowData: any) => ({
                icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
                onClick: () => initRemoveProcedure(rowData.ID),
                tooltip: t('common.remove'),
            })),
    ]

    const initRemoveProcedure = (schedulerId: number) => {
        setOpenAlertModal(true)
        setSelectedElementToRemove(schedulerId)
    }

    const confirmRemoveEvent = async () => {
        try {
            await CustomAxios.delete(`devices/${selectedElementToRemove}`)
            exitRemoveProcedure()
            resetData(currentPage, '', '', true)
        } catch (error) {
            console.error(error)
        }
    }

    const exitRemoveProcedure = () => {
        setOpenAlertModal(false)
        setSelectedElementToRemove(-1)
    }

    useEffect(() => {
        resetData(currentPage, '', '', true)
    }, [currentNetwork])

    useEffect(() => {
        if (tableRef.current && props.filterParams) {
            resetData(currentPage, '', '', true)
        }
    }, [props.filterParams])

    useEffect(() => {
        if (tableRef.current && props.reloads !== undefined) {
            resetData(currentPage, '', '', true)
        }
    }, [props.reloads])

    const resetData = useCallback((page = 0, headerToSortBy = '', order = '', forceReload = false) => {
        if (page === currentPage && !forceReload) return
        tableRef.current.onQueryChange({ page, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    return (
        <>
            {openAlertModal && (
                <AlertModal
                    alertMessage={deleteMessage}
                    onClose={() => exitRemoveProcedure()}
                    onConfirm={() => void confirmRemoveEvent()}
                />
            )}
            {devicesLimit > 0 ? (
                <div
                    style={{
                        display: 'flex',
                        gap: 8,
                        marginLeft: 30,
                        marginTop: 10,
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Chip
                        label={`${totalElements}/${devicesLimit} ${t('common.registeredDevices')}`}
                        color={devicesLimit <= totalElements ? 'secondary' : 'default'}
                    />
                    <Tooltip title={t('common.devicesQuotaRequest')}>
                        <div>
                            <FontAwesomeIcon icon={faCircleInfo} color={COLORS.palette.darkGrey} />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                actions={actions}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'devices'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        if (currentNetwork !== undefined) {
                            url += `&network=${currentNetwork.ID}`
                        }
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.filterParams && props.filterParams !== null) {
                            Object.keys(props.filterParams).map((key: string) => {
                                url += `&${key}=${props.filterParams[key]}`
                                return
                            })
                        }
                        void CustomAxios.get(url).then((response) => {
                            resolve({
                                data: response.data.data,
                                page: query.page,
                                totalCount: handleDataCount(response.data.count),
                            })
                            if (firstDataLoad && props.onFinish) {
                                props.onFinish()
                                setFirstDataLoad(false)
                            }
                        })
                    })
                }
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                onChangePage={(pageIndex) => {
                    setCurrentPage(pageIndex)
                    navigate(`/${ROUTE_NAMES.LIST}?page=${pageIndex}`)
                }}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 6,
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default RemoteControlsTable
