import { faCircleInfo, faDashboard } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../Components/StaticComponents/Loader'
import CustomAxios from '../../customComponents/customAxios'
import '../../styles/pages/main.sass'
import GrafanaAdapter from '../Analytics/GrafanaAdapter'
import Button from '../../Components/Inputs/Button'
import RealtimeModal from '../../Components/Modals/RealtimeModal'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import { Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLORS } from '../../styles/constants'

interface Props {
    device: any
}

const DeviceRealtimeSection: React.FC<Props> = ({ device }) => {
    const [dashboardData, setDashboardData] = useState<any>()
    const [selectedDashboard, setSelectedDashboard] = useState<SelectOptionValue | null>()
    const [loading, setLoading] = useState(false)
    const [realtimeModalIsVisible, setRealtimeModalIsVisible] = useState(false)
    const { t } = useTranslation()

    const dashboardsOptions = useMemo(() => {
        return [
            {
                label: 'Custom',
                value: '1',
            },
            {
                label: 'Default',
                value: '2',
            },
        ]
    }, [])

    console.log('device', device)

    const getDashboards = async () => {
        // if (!device || !device.ID || Number.isNaN(device.ID)) {
        //     console.error('Invalid device or device ID:', device);
        //     return;
        // }
        try {
            setLoading(true)
            let data: any = await CustomAxios.get(`dashboards?device=${device.ID}`, {}).then(
                (response) => response.data.data[0]
            )
            if (data === undefined) {
                data = {}
            }
            const grafanaDashboard: any = await CustomAxios.get(`grafana/dashboards?device=${device.ID}`, {}).then(
                (response) => response.data
            )
            data.grafanaDeviceDashboard = grafanaDashboard

            if (grafanaDashboard !== undefined && grafanaDashboard.length > 0) {
                setSelectedDashboard(dashboardsOptions[1])
            } else {
                setLoading(true)
                await CustomAxios.post('grafana/dashboard-device', { device: device.ID, overwrite: true }).then(
                    (response) => response.data
                )
                const grafanaDashboard: any = await CustomAxios.get(`grafana/dashboards?device=${device.ID}`, {}).then(
                    (response) => response.data
                )
                data.grafanaDeviceDashboard = grafanaDashboard
                setSelectedDashboard(dashboardsOptions[0])
                // setLoading(false)
            }

            setDashboardData(data)
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        setSelectedDashboard(dashboardsOptions[0])
        if (device && device.ID) {
            void getDashboards()
        }
    }, [device, dashboardsOptions])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [])

    if (!device) {
        return <div>{t('fields.deviceNotFound')}</div>
    }

    return (
        <>
            {realtimeModalIsVisible && (
                <RealtimeModal
                    idDevice={device.ID}
                    onClose={() => {
                        setRealtimeModalIsVisible(false)
                    }}
                />
            )}
            <div className="sectionContent no-padding">
                <div className="headerSection row-header-section">
                    {device.idCommunicationProtocol !== 2 && (
                        <Button
                            label={t('fields.realtimeInfo')}
                            backgroundColor="#e10915"
                            textColor="#ffffff"
                            margin="0 25px 0 0"
                            iconColor="#ffffff"
                            icon={faDashboard}
                            onPress={() => {
                                setRealtimeModalIsVisible(true)
                            }}
                        />
                    )}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 12,
                            justifyContent: 'flex-end',
                            flex: 1,
                        }}
                    >
                        <span style={{ fontSize: '.8em' }}>{t('fields.dashboardsSelectorText')}</span>
                        <div style={{ display: 'flex' }}>
                            <SelectInput
                                isSmall
                                styles={['device-page-select']}
                                label={''}
                                value={selectedDashboard}
                                onValueChange={async (value) => {
                                    setLoading(true)
                                    if (
                                        value.value === '1' &&
                                        (dashboardData.grafanaDeviceDashboard === undefined ||
                                            dashboardData.grafanaDeviceDashboard.length === 0)
                                    ) {
                                        await CustomAxios.post('grafana/dashboard-device', {
                                            device: device.ID,
                                            overwrite: true,
                                        }).then((response) => response.data)
                                        void getDashboards()
                                    }
                                    setSelectedDashboard(value)
                                    setTimeout(() => {
                                        setLoading(false)
                                    }, 3000)
                                }}
                                options={dashboardsOptions.map((dashboardOption) => {
                                    return { label: dashboardOption.label, value: dashboardOption.value.toString() }
                                })}
                                width={'100%'}
                            />
                        </div>
                        <Tooltip title={t('fields.dashboardsSelectorHelpText')}>
                            <div>
                                <FontAwesomeIcon icon={faCircleInfo} color={COLORS.palette.darkGrey} />
                            </div>
                        </Tooltip>
                    </div>
                </div>
                {device.idDeviceModel !== null ? (
                    <>
                        {loading && <Loader />}
                        <GrafanaAdapter
                            hide={loading}
                            selectedDashboard={selectedDashboard ? selectedDashboard.label.toString() : ''}
                            dashboardData={{
                                ...dashboardData,
                                deviceID: device.ID,
                                nominalPower: device.deviceInfo
                                    ? device.deviceInfo.find((e) => e.name === 'nominal_power')
                                    : null,
                            }}
                        />
                    </>
                ) : null}
            </div>
        </>
    )
}

export default DeviceRealtimeSection

/* 
import { faCircleInfo, faDashboard } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../Components/StaticComponents/Loader';
import CustomAxios from '../../customComponents/customAxios';
import '../../styles/pages/main.sass';
import GrafanaAdapter from '../Analytics/GrafanaAdapter';
import Button from '../../Components/Inputs/Button';
import RealtimeModal from '../../Components/Modals/RealtimeModal';
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLORS } from '../../styles/constants';

interface Props {
    device: any;
}

const DeviceRealtimeSection: React.FC<Props> = ({ device }) => {
    const [dashboardData, setDashboardData] = useState<any>();
    const [selectedDashboard, setSelectedDashboard] = useState<SelectOptionValue | null>();
    const [loading, setLoading] = useState(false);
    const [realtimeModalIsVisible, setRealtimeModalIsVisible] = useState(false);
    const { t } = useTranslation();

    const dashboardsOptions = useMemo(() => {
        return [
            {
                label: 'Custom',
                value: '1',
            },
            {
                label: 'Default',
                value: '2',
            },
        ];
    }, []);

    const getDashboards = async () => {
        try {
            setLoading(true);
            let data: any = await CustomAxios.get(`dashboards?device=${device.ID}`, {}).then(
                (response) => response.data.data[0]
            );
            if (data === undefined) {
                data = {};
            }
            const grafanaDashboard: any = await CustomAxios.get(
                `grafana/dashboards?device=${device.ID}`,
                {}
            ).then((response) => response.data);
            data.grafanaDeviceDashboard = grafanaDashboard;

            if (grafanaDashboard !== undefined && grafanaDashboard.length > 0) {
                setSelectedDashboard(dashboardsOptions[1]);
            } else {
                setLoading(true);
                await CustomAxios.post('grafana/dashboard-device', { device: device.ID, overwrite: true }).then(
                    (response) => response.data
                );
                const grafanaDashboard: any = await CustomAxios.get(
                    `grafana/dashboards?device=${device.ID}`,
                    {}
                ).then((response) => response.data);
                data.grafanaDeviceDashboard = grafanaDashboard;
                setSelectedDashboard(dashboardsOptions[0]);
                setLoading(false);
            }

            setDashboardData(data);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        setSelectedDashboard(dashboardsOptions[0]);
        void getDashboards();
    }, [device.ID, dashboardsOptions]);  // eslint-disable-line
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);

    return (
        <>
            {realtimeModalIsVisible && (
                <RealtimeModal
                    idDevice={device.ID}
                    onClose={() => {
                        setRealtimeModalIsVisible(false);
                    }}
                />
            )}
            <div className="sectionContent no-padding">
                <div className="headerSection row-header-section">
                    {device.idCommunicationProtocol !== 2 && (
                        <Button
                            label={t('fields.realtimeInfo')}
                            backgroundColor="#e10915"
                            textColor="#ffffff"
                            margin="0 25px 0 0"
                            iconColor="#ffffff"
                            icon={faDashboard}
                            onPress={() => {
                                setRealtimeModalIsVisible(true);
                            }}
                        />
                    )}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 12,
                            justifyContent: 'flex-end',
                            flex: 1,
                        }}
                    >
                        <span style={{ fontSize: '.8em' }}>{t('fields.dashboardsSelectorText')}</span>
                        <div style={{ display: 'flex' }}>
                            <SelectInput
                                isSmall
                                styles={['device-page-select']}
                                label={''}
                                value={selectedDashboard}
                                onValueChange={async (value) => {
                                    setLoading(true);
                                    if (
                                        value.value === '1' &&
                                        (dashboardData.grafanaDeviceDashboard === undefined ||
                                            dashboardData.grafanaDeviceDashboard.length === 0)
                                    ) {
                                        await CustomAxios.post('grafana/dashboard-device', {
                                            device: device.ID,
                                            overwrite: true,
                                        }).then((response) => response.data);
                                        void getDashboards();
                                    }
                                    setSelectedDashboard(value);
                                    setTimeout(() => {
                                        setLoading(false);
                                    }, 3000);
                                }}
                                options={dashboardsOptions.map((dashboardOption) => {
                                    return { label: dashboardOption.label, value: dashboardOption.value.toString() };
                                })}
                                width={'100%'}
                            />
                        </div>
                        <Tooltip title={t('fields.dashboardsSelectorHelpText')}>
                            <div>
                                <FontAwesomeIcon icon={faCircleInfo} color={COLORS.palette.darkGrey} />
                            </div>
                        </Tooltip>
                    </div>
                </div>
                {device.idDeviceModel !== null ? (
                    <>
                        {loading && <Loader />}
                        <GrafanaAdapter
                            hide={loading}
                            selectedDashboard={selectedDashboard ? selectedDashboard.label.toString() : ''}
                            dashboardData={{
                                ...dashboardData,
                                deviceID: device.ID,
                                nominalPower: device.deviceInfo
                                    ? device.deviceInfo.find((e) => e.name === 'nominal_power')
                                    : null,
                            }}
                        />
                    </>
                ) : null}
            </div>
        </>
    );
};

export default DeviceRealtimeSection; */
