import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import { persistor, store } from './store/reducers'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import italian from './resources/locales/it.json'
import english from './resources/locales/en.json'
import ukrainian from './resources/locales/uk.json' 

import { useEffect } from 'react'

const translations = {
    en: {
        translation: english,
    },
    it: {
        translation: italian,
    },
    uk: {                         // Aggiungi le traduzioni ucraine
        translation: ukrainian,
    },
}

const fallbackLanguage = 'en'

const Setup: React.FC = () => {
    useEffect(() => {
        const language = navigator.languages !== undefined ? navigator.languages[0] : navigator.language
        void i18n.use(initReactI18next).init({
            resources: translations,
            lng: language || fallbackLanguage,
            fallbackLng: fallbackLanguage,
            interpolation: {
                escapeValue: false,
            },
        })
    }, [])

    return (
        <Provider store={store}>
            <PersistGate loading={<div />} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    )
}

export default Setup
