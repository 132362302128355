import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import CustomAxios from '../../customComponents/customAxios'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import DeleteModal from '../Modals/DeleteModal'
import { usePrevious, useTablePagination } from '../../utils/hooks/common'
import { UserDataTable } from '../../types/data/user'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { ReducerData, Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { COLORS } from '../../styles/constants'

interface Props {
    searchQuery: string
    onFinish?: () => void
}

const UsersTable: React.FC<Props> = (props) => {
    const { currentNetwork } = useSelector<Reducers, ReducerData>((store) => store.data)

    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [selectedUser, setSelectedUser] = useState<UserDataTable | null>(null)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [firstDataLoad, setFirstDataLoad] = useState(true)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const tableRef = React.useRef<any>()
    const navigate = useNavigate()
    const previousQuery = usePrevious(props.searchQuery)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const currentPageFromURL = useTablePagination('page')

    useEffect(() => {
        handlePageDataUpdate()
    }, [])

    const handlePageDataUpdate = useCallback(() => {
        const currentPageValue = currentPageFromURL > 0 ? currentPageFromURL : 0
        if (currentPageValue * pageSize <= totalElements) {
            setCurrentPage(currentPageValue)
            resetData(currentPageValue)
        }
    }, [currentPageFromURL, totalElements, pageSize])

    const handleDataCount = (totCounter: number): number => {
        setTotalElements(totCounter)
        return totCounter
    }

    const removeHandler = async (user: UserDataTable) => {
        try {
            await CustomAxios.delete(`users/user/${user.ID}`)
            resetData(currentPage, '', '', true)
            setSelectedUser(null)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (tableRef.current && (props.searchQuery.length > 2 || (previousQuery && previousQuery.length > 2))) {
            resetData(currentPage, '', '', true)
        }
    }, [props.searchQuery])

    useEffect(() => {
        resetData(currentPage, '', '', true)
    }, [currentNetwork])

    const resetData = useCallback((page = 0, headerToSortBy = '', order = '', forceReload = false) => {
        if (page === currentPage && !forceReload) return
        tableRef.current.onQueryChange({ page, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('fields.name'),
                field: 'first_name',
                render: (rowData: UserDataTable) => (
                    <span
                        className="colorRed table-link-value"
                        onClick={() => navigate(`/${ROUTE_NAMES.USERS}/${rowData?.ID}`, { state: { isView: true } })}
                    >
                        {rowData.firstName} {rowData.lastName}
                    </span>
                ),
            },
            {
                title: t('fields.email'),
                field: 'email',
            },
            {
                title: t('fields.associatedNetworks'),
                field: 'associated_networks',
                render: (rowData: UserDataTable) => {
                    if (rowData.networks.length === 0) {
                        return '-'
                    }
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {rowData.networks.map((n) => {
                                return (
                                    <span
                                        key={n.ID}
                                        className="colorRed table-link-value"
                                        onClick={() => navigate(`/${ROUTE_NAMES.NETWORKS}/${n.ID}`)}
                                    >
                                        {n.name}
                                    </span>
                                )
                            })}
                        </div>
                    )
                },
            },
        ]
        return columns
    }, [])

    const actions = useMemo(() => {
        const actions = [
            (rowData: UserDataTable) =>
                (user.idSysGrant === 0 ||
                    user.managedNetworks.find((networkId) =>
                        rowData.networks.find((network) => network.ID === networkId)
                    )) && {
                    icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
                    onClick: () => navigate(`/${ROUTE_NAMES.USERS}/${rowData?.ID}`),
                    tooltip: t('common.edit'),
                },
            (rowData: UserDataTable) =>
                (user.idSysGrant === 0 ||
                    user.managedNetworks.find((networkId) =>
                        rowData.networks.find((network) => network.ID === networkId)
                    )) && {
                    icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
                    onClick: () => setSelectedUser(rowData),
                    tooltip: t('common.remove'),
                },
        ]
        return actions
    }, [])

    return (
        <>
            {selectedUser ? (
                <DeleteModal
                    deleteMessage={t('systemMessages.userDeleteConfirmMessage', {
                        user: `${selectedUser.firstName} ${selectedUser.lastName}`,
                    })}
                    onClose={() => setSelectedUser(null)}
                    onDelete={() => void removeHandler(selectedUser)}
                />
            ) : null}
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'users'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.searchQuery && props.searchQuery.length > 2) {
                            url += `&query=${props.searchQuery}`
                        }
                        void CustomAxios.get(url).then((response) => {
                            let users = response.data.data.filter((user) => user.hide !== 1)
                            if (currentNetwork !== undefined) {
                                users = users.filter((user) => user.networks.find((n) => n.ID === currentNetwork.ID))
                            }
                            resolve({
                                data: users,
                                page: query.page,
                                totalCount: handleDataCount(users.length),
                            })
                            if (firstDataLoad && props.onFinish) {
                                props.onFinish()
                                setFirstDataLoad(false)
                            }
                        })
                    })
                }
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 3,
                }}
                onChangePage={(pageIndex) => {
                    setCurrentPage(pageIndex)
                    navigate(`/${ROUTE_NAMES.USERS}?page=${pageIndex}`)
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default UsersTable
