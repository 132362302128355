import React, { useEffect, useMemo, useState } from 'react'
import Footer from '../../Components/StaticComponents/Footer'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import TodayAlarmTable from '../../Components/Tables/TodayAlarmTable'
import '../../styles/pages/main.sass'
import '../../styles/pages/alarms.sass'
import { faEyeSlash, faRedo, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import Button from '../../Components/Inputs/Button'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import { Network, NetworkGroup } from '../../types/data/system'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import CustomAxios from '../../customComponents/customAxios'
import Datepicker from '../../Components/Datepicker'
import PageHeaderCustom from '../../customComponents/PageHeaderCustom'
import AlertModal from '../../Components/Modals/AlertModal'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers } from '../../types/reducers'

const TodayAlarmPage: React.FC = () => {
    const { t } = useTranslation()
    const { currentNetwork } = useSelector<Reducers, ReducerData>((store) => store.data)

    const [availableNetworks, setAvailableNetworks] = useState<Network[]>([])
    const [deviceNetwork, setDeviceNetwork] = useState<SelectOptionValue | null>({
        label: t('fields.network'),
        value: '',
    })
    const [deviceGroup, setDeviceGroup] = useState<SelectOptionValue | null>({ label: t('fields.group'), value: '' })
    const [allNetworkGroups, setAllNetworkGroups] = useState<NetworkGroup[]>([])
    const [filterPanelIsVisible, setFilterPanelIsVisible] = useState(false)
    const [toggleReset, setToggleReset] = useState(undefined)
    const [selectedThresholdFilter, setSelectedThresholdFilter] = useState(2)
    const [filterParameters, setFilterParameters] = useState<any | null>(null)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [filterStartDate, setFilterStartDate] = useState('')
    const [filterEndDate, setFilterEndDate] = useState('')

    const thresholdFilters = [
        {
            id: 0,
            value: 'onlyBiggerFinalValues',
            label: t('fields.valueBiggerThanThreshold'),
        },
        {
            id: 1,
            value: 'onlySmallerFinalValues',
            label: t('fields.valueSmallerThanThreshold'),
        },
        {
            id: 2,
            value: 'all',
            label: t('fields.allCases'),
        },
    ]

    useEffect(() => {
        if (currentNetwork) {
            setDeviceNetwork({ label: currentNetwork.name, value: currentNetwork.ID.toString() })
            setFilterParameters(getFilterParameters(currentNetwork.ID.toString()))
        } else {
            setDeviceNetwork({ label: t('fields.network'), value: '' })
            setFilterParameters(getFilterParameters('-1'))
        }
    }, [currentNetwork])

    const getFilterParameters = (idNetwork?: string) => {
        const filterParameters: any = {
            from: filterStartDate,
            to: filterEndDate,
            group: deviceGroup.value === '' ? -1 : parseInt(deviceGroup.value, 10),
            network: idNetwork
                ? parseInt(idNetwork, 10)
                : deviceNetwork.value === ''
                ? -1
                : parseInt(deviceNetwork.value, 10),
            threshold: selectedThresholdFilter === 0 ? 'MAX' : selectedThresholdFilter === 1 ? 'MIN' : '',
        }
        Object.keys(filterParameters).forEach((key) => {
            const attributeValue = filterParameters[key]
            if (attributeValue === '' || attributeValue === null || attributeValue === -1) {
                delete filterParameters[key]
            }
        })
        if (Object.keys(filterParameters).length > 0) {
            return { ...filterParameters }
        }
        return {}
    }

    const filterParametersChanged = useMemo(() => {
        return (
            filterStartDate !== '' ||
            filterEndDate !== '' ||
            deviceNetwork.value !== '' ||
            deviceGroup.value !== '' ||
            !Number.isNaN(selectedThresholdFilter)
        )
    }, [filterStartDate, filterEndDate, deviceGroup, deviceNetwork, selectedThresholdFilter])

    const getDetailedFilteredEvents = () => {
        setFilterParameters(getFilterParameters())
    }

    const fetchNetworks = async () => {
        try {
            const networks = await CustomAxios.get('networks').then((response) => response.data.data)
            setAvailableNetworks(networks)
        } catch (error) {
            console.error(error)
        }
    }
    const hideEvents = async () => {
        try {
            let url = 'device-events/resolve?_=0'
            if (filterParameters !== null) {
                Object.keys(filterParameters).map((key: string) => {
                    url += `&${key}=${filterParameters[key]}`
                    return
                })
            }
            await CustomAxios.get(url).then((response) => response.data.data)
            setOpenAlertModal(false)
            setToggleReset(!toggleReset)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (filterPanelIsVisible) {
            void fetchNetworks()
        }
    }, [filterPanelIsVisible])

    const resetFilterParameters = () => {
        setFilterStartDate('')
        setFilterEndDate('')
        setDeviceGroup({ label: t('fields.group'), value: '' })
        setDeviceNetwork({ label: t('fields.network'), value: '' })
        setSelectedThresholdFilter(2)
        setToggleReset(!toggleReset)
        setFilterParameters(null)
    }

    const getAvailableNetworkGroups = async () => {
        try {
            const groups: NetworkGroup[] = await CustomAxios.get('network-groups').then(
                (response) => response.data.data
            )
            setAllNetworkGroups(groups)
        } catch (error) {
            console.error(error)
        }
    }

    const availableNetworkGroups = useMemo(() => {
        setDeviceGroup({ label: t('fields.group'), value: '' })
        if (deviceNetwork.value !== '') {
            return allNetworkGroups.filter(
                (networkGroup: NetworkGroup) => networkGroup.idNetwork.toString() === deviceNetwork.value
            )
        }
        return []
    }, [deviceNetwork, allNetworkGroups])

    useEffect(() => {
        void getAvailableNetworkGroups()
    }, [])

    const networkSelection = useMemo(() => {
        if (availableNetworks.length === 0) {
            return null
        }
        return (
            <SelectInput
                value={deviceNetwork}
                onValueChange={(value) => setDeviceNetwork(value)}
                options={availableNetworks.map((network: Network) => {
                    return { label: network.name, value: network.ID.toString() }
                })}
                width={'100%'}
            />
        )
    }, [availableNetworks, deviceGroup])

    return (
        <PageContainer>
            <div className="headerSection">
                <PageHeaderCustom titleKey="routes.eventsList" descriptionKey="pageDescription.eventsList" />
                <div className="header-right-actions">
                    {openAlertModal && (
                        <AlertModal
                            alertMessage={t('systemMessages.hideEvents')}
                            onClose={() => setOpenAlertModal(false)}
                            onConfirm={async () => {
                                await hideEvents()
                            }}
                        />
                    )}
                    <Button
                        label={t('fields.filterEvents')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0 25px 0 0"
                        borderColor={filterPanelIsVisible ? '#212121' : undefined}
                        icon={faSlidersH}
                        onPress={() => setFilterPanelIsVisible(!filterPanelIsVisible)}
                    />
                    <Button
                        label={t('common.update')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0"
                        icon={faRedo}
                        onPress={() => setToggleReset(!toggleReset)}
                    />
                    <Button
                        label={t('fields.hideAll')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0 0 0 25px"
                        icon={faEyeSlash}
                        onPress={() => setOpenAlertModal(true)}
                    />
                </div>
            </div>
            <div className="sectionContent">
                <div>
                    {filterPanelIsVisible && (
                        <div className="advanced-search-wrapper">
                            <div className="search-wrapper-body">
                                <div className="filter-column">
                                    <h3>{t('fields.belongingDevices')}</h3>
                                    <div className="input-rows" style={{ zIndex: 11 }}>
                                        {networkSelection}
                                        <SelectInput
                                            disabled={deviceNetwork.value === ''}
                                            value={deviceGroup}
                                            onValueChange={(value) => setDeviceGroup(value)}
                                            options={availableNetworkGroups.map((networkGroup: NetworkGroup) => {
                                                if (networkGroup.idNetwork.toString() === deviceNetwork.value) {
                                                    return {
                                                        label: networkGroup.name,
                                                        value: networkGroup.id.toString(),
                                                    }
                                                }
                                                return
                                            })}
                                            width={'100%'}
                                        />
                                    </div>
                                </div>
                                <div className="filter-column">
                                    <h3>{t('routes.events')}</h3>
                                    <div className="input-rows">
                                        <RadioGroup
                                            name="radio-buttons-group"
                                            value={selectedThresholdFilter}
                                            onChange={(e) => setSelectedThresholdFilter(parseInt(e.target.value, 10))}
                                        >
                                            {thresholdFilters.map((filter) => {
                                                return (
                                                    <FormControlLabel
                                                        key={filter.id}
                                                        value={filter.id}
                                                        control={<Radio />}
                                                        label={filter.label}
                                                    />
                                                )
                                            })}
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div className="filter-column">
                                    <h3>{t('fields.searchInterval')}</h3>
                                    <div className="calendar-selection-container">
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectStartOfInterval')}
                                            onDateChange={(newDate) => setFilterStartDate(newDate)}
                                        />
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectEndOfInterval')}
                                            onDateChange={(newDate) => setFilterEndDate(newDate)}
                                            minSelectableDate={filterStartDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-wrapper-action-section">
                                <button
                                    disabled={filterParameters === null}
                                    className="filter-secondary-button"
                                    onClick={() => resetFilterParameters()}
                                >
                                    {t('common.reset')}
                                </button>
                                <button
                                    disabled={!filterParametersChanged}
                                    className="standard-confirm-button"
                                    onClick={() => getDetailedFilteredEvents()}
                                >
                                    {t('common.search')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <TodayAlarmTable reloads={toggleReset} filterParams={filterParameters} />
                <Footer />
            </div>
        </PageContainer>
    )
}

export default TodayAlarmPage
