import React, { useEffect, useMemo, useState } from 'react'
import Footer from '../../Components/StaticComponents/Footer'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import '../../styles/pages/main.sass'
import '../../styles/pages/alarms.sass'
import { faRedo, faSlidersH, faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from '../../Components/Inputs/Button'
import TextInput from '../../Components/Inputs/TextInput'
import CheckboxInput from '../../Components/Inputs/CheckboxInput'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import EventsHistoryTable from '../../Components/Tables/EventsHistoryTable'
import { Brand, Model, Network, NetworkGroup } from '../../types/data/system'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import Datepicker from '../../Components/Datepicker'
import PageHeaderCustom from '../../customComponents/PageHeaderCustom'
import CustomAxios from '../../customComponents/customAxios'
import AlertModal from '../../Components/Modals/AlertModal'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers } from '../../types/reducers'

const EventsHistory: React.FC = () => {
    const { t } = useTranslation()
    const { currentNetwork } = useSelector<Reducers, ReducerData>((store) => store.data)

    const [deviceNetwork, setDeviceNetwork] = useState<SelectOptionValue | null>({
        label: t('fields.network'),
        value: '',
    })
    const [deviceGroup, setDeviceGroup] = useState<SelectOptionValue | null>({ label: t('fields.group'), value: '' })
    const [deviceBrand, setDeviceBrand] = useState<SelectOptionValue | null>({ label: t('fields.brand'), value: '' })
    const [deviceModel, setDeviceModel] = useState<SelectOptionValue | null>({ label: t('fields.model'), value: '' })
    const [availableNetworks, setAvailableNetworks] = useState<Network[]>([])
    const [allNetworkGroups, setAllNetworkGroups] = useState<NetworkGroup[]>([])
    const [availableBrands, setAvailableBrands] = useState<Brand[]>([])
    const [allModels, setAllModels] = useState<Model[]>([])
    const [querySearch, setQuerySearch] = useState('')
    const [toggleReset, setToggleReset] = useState(undefined)
    const [selectedThresholdFilter, setSelectedThresholdFilter] = useState(2)
    const [searchThroughOpenAlarms, setSearchThroughOpenAlarms] = useState(false)
    const [filterPanelIsVisible, setFilterPanelIsVisible] = useState(false)
    const [filterParameters, setFilterParameters] = useState<any | null>(null)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [filterStartDate, setFilterStartDate] = useState('')
    const [filterEndDate, setFilterEndDate] = useState('')

    const thresholdFilters = [
        {
            id: 0,
            value: 'onlyBiggerFinalValues',
            label: t('fields.valueBiggerThanThreshold'),
        },
        {
            id: 1,
            value: 'onlySmallerFinalValues',
            label: t('fields.valueSmallerThanThreshold'),
        },
        {
            id: 2,
            value: 'all',
            label: t('fields.allCases'),
        },
    ]

    useEffect(() => {
        if (currentNetwork) {
            setDeviceNetwork({ label: currentNetwork.name, value: currentNetwork.ID.toString() })
            setFilterParameters(getFilterParameters(currentNetwork.ID.toString()))
        } else {
            setDeviceNetwork({ label: t('fields.network'), value: '' })
            setFilterParameters(getFilterParameters('-1'))
        }
    }, [currentNetwork])

    const getAvailableNetworks = async () => {
        try {
            const networks: Network[] = await CustomAxios.get('networks').then((response) => response.data.data)
            setAvailableNetworks(networks)
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableNetworkGroups = async () => {
        try {
            const groups: NetworkGroup[] = await CustomAxios.get('network-groups').then(
                (response) => response.data.data
            )
            setAllNetworkGroups(groups)
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableBrands = async () => {
        try {
            const brands: Brand[] = await CustomAxios.get('brands').then((response) => response.data.data)
            setAvailableBrands(brands)
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableModels = async () => {
        try {
            const allModels: Model[] = await CustomAxios.get('device-models').then((response) => response.data.data)
            setAllModels(allModels)
        } catch (error) {
            console.error(error)
        }
    }

    const getGeneralData = async () => {
        try {
            await getAvailableNetworks()
            await getAvailableNetworkGroups()
            await getAvailableBrands()
            await getAvailableModels()
        } catch (error) {
            console.error(error)
        }
    }

    const deleteEvents = async () => {
        try {
            let url = 'device-events/resolve?detailed=true'
            if (filterParameters !== null) {
                Object.keys(filterParameters).map((key: string) => {
                    url += `&${key}=${filterParameters[key]}`
                    return
                })
            }
            await CustomAxios.get(url).then((response) => response.data.data)
            setOpenAlertModal(false)
            setToggleReset(!toggleReset)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void getGeneralData()
    }, [])

    const updateDeviceBrand = (selectedBrandOption: SelectOptionValue) => {
        setDeviceBrand(selectedBrandOption)
        setDeviceModel({ label: t('fields.model'), value: '' })
    }

    const updateDeviceNetwork = (selectedNetworkOption: SelectOptionValue) => {
        setDeviceNetwork(selectedNetworkOption)
        setDeviceGroup({ label: t('fields.group'), value: '' })
    }

    const getFilterParameters = (idNetwork?: string) => {
        const filterParameters: any = {
            query: querySearch,
            from: filterStartDate,
            to: filterEndDate,
            closed: !searchThroughOpenAlarms ? undefined : false,
            model: deviceModel.value === '' ? -1 : parseInt(deviceModel.value, 10),
            brand: deviceBrand.value === '' ? -1 : parseInt(deviceBrand.value, 10),
            group: deviceGroup.value === '' ? -1 : parseInt(deviceGroup.value, 10),
            network: idNetwork
                ? parseInt(idNetwork, 10)
                : deviceNetwork.value === ''
                ? -1
                : parseInt(deviceNetwork.value, 10),
            threshold: selectedThresholdFilter === 0 ? 'MAX' : selectedThresholdFilter === 1 ? 'MIN' : '',
        }
        Object.keys(filterParameters).forEach((key) => {
            const attributeValue = filterParameters[key]
            if (
                attributeValue === '' ||
                attributeValue === null ||
                attributeValue === -1 ||
                attributeValue === undefined
            ) {
                delete filterParameters[key]
            }
        })
        if (Object.keys(filterParameters).length > 0) {
            return { ...filterParameters }
        }
        return {}
    }

    const filterParametersChanged = useMemo(() => {
        return (
            querySearch !== '' ||
            filterStartDate !== '' ||
            filterEndDate !== '' ||
            !Number.isNaN(selectedThresholdFilter) ||
            searchThroughOpenAlarms ||
            deviceModel.value !== '' ||
            deviceBrand.value !== '' ||
            deviceGroup.value !== '' ||
            deviceNetwork.value !== ''
        )
    }, [
        querySearch,
        filterStartDate,
        filterEndDate,
        selectedThresholdFilter,
        searchThroughOpenAlarms,
        deviceModel,
        deviceBrand,
        deviceGroup,
        deviceNetwork,
    ])

    const getDetailedFilteredEvents = () => {
        setFilterParameters(getFilterParameters())
    }

    const resetFilterParameters = () => {
        setFilterStartDate('')
        setFilterEndDate('')
        setQuerySearch('')
        setDeviceBrand({ label: t('fields.brand'), value: '' })
        setDeviceModel({ label: t('fields.model'), value: '' })
        setDeviceGroup({ label: t('fields.group'), value: '' })
        setDeviceNetwork({ label: t('fields.network'), value: '' })
        setSelectedThresholdFilter(2)
        setSearchThroughOpenAlarms(false)
        setFilterParameters(null)
        setToggleReset(!toggleReset)
    }

    const availableModels = useMemo(() => {
        if (deviceBrand.value !== '') {
            return allModels.filter((model: Model) => model.idBrand.toString() === deviceBrand.value)
        }
        return []
    }, [deviceBrand])

    const availableNetworkGroups = useMemo(() => {
        if (deviceNetwork.value !== '') {
            return allNetworkGroups.filter(
                (networkGroup: NetworkGroup) => networkGroup.idNetwork.toString() === deviceNetwork.value
            )
        }
        return []
    }, [deviceNetwork])

    return (
        <PageContainer>
            <div className="headerSection">
                <PageHeaderCustom titleKey="routes.eventsHistory" descriptionKey="pageDescription.eventsHistory" />
                {openAlertModal && (
                    <AlertModal
                        alertMessage={t('systemMessages.deleteEvents')}
                        onClose={() => setOpenAlertModal(false)}
                        onConfirm={async () => {
                            await deleteEvents()
                        }}
                    />
                )}
                <div className="header-right-actions">
                    <Button
                        label={t('fields.filterEvents')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0 25px 0 0"
                        borderColor={filterPanelIsVisible ? '#212121' : undefined}
                        icon={faSlidersH}
                        onPress={() => setFilterPanelIsVisible(!filterPanelIsVisible)}
                    />
                    <Button
                        label={t('common.update')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0"
                        icon={faRedo}
                        onPress={() => setToggleReset(!toggleReset)}
                    />
                    <Button
                        label={t('fields.deleteAll')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0 0 0 25px"
                        icon={faTrash}
                        onPress={() => setOpenAlertModal(true)}
                    />
                </div>
            </div>
            <div className="sectionContent">
                <div>
                    {filterPanelIsVisible && (
                        <div className="advanced-search-wrapper">
                            <div className="search-wrapper-body">
                                <div className="filter-column">
                                    <h3>{t('fields.devices')}</h3>
                                    <TextInput
                                        placeholder={t('fields.searchByVariableOrDevice')}
                                        label=""
                                        value={querySearch}
                                        onChangeText={(newValue: string) => setQuerySearch(newValue)}
                                    />
                                    <div className="input-rows">
                                        <SelectInput
                                            value={deviceBrand}
                                            onValueChange={(value) => updateDeviceBrand(value)}
                                            options={availableBrands.map((brand: Brand) => {
                                                return { label: brand.name, value: brand.ID.toString() }
                                            })}
                                            width={'100%'}
                                        />
                                        <SelectInput
                                            disabled={deviceBrand.value === ''}
                                            value={deviceModel}
                                            onValueChange={(value) => setDeviceModel(value)}
                                            options={availableModels.map((model: Model) => {
                                                if (model.idBrand.toString() === deviceBrand.value) {
                                                    return { label: model.model, value: model.ID.toString() }
                                                }
                                                return
                                            })}
                                            width={'100%'}
                                        />
                                    </div>
                                    <div className="input-rows">
                                        <SelectInput
                                            value={deviceNetwork}
                                            onValueChange={(value) => updateDeviceNetwork(value)}
                                            options={availableNetworks.map((network: Network) => {
                                                return { label: network.name, value: network.ID.toString() }
                                            })}
                                            width={'100%'}
                                        />
                                        <SelectInput
                                            disabled={deviceNetwork.value === ''}
                                            value={deviceGroup}
                                            onValueChange={(value) => setDeviceGroup(value)}
                                            options={availableNetworkGroups.map((networkGroup: NetworkGroup) => {
                                                if (networkGroup.idNetwork.toString() === deviceNetwork.value) {
                                                    return {
                                                        label: networkGroup.name,
                                                        value: networkGroup.id.toString(),
                                                    }
                                                }
                                                return
                                            })}
                                            width={'100%'}
                                        />
                                    </div>
                                </div>
                                <div className="filter-column">
                                    <h3>{t('routes.events')}</h3>
                                    <div className="input-rows">
                                        <RadioGroup
                                            name="radio-buttons-group"
                                            value={selectedThresholdFilter}
                                            onChange={(e) => setSelectedThresholdFilter(parseInt(e.target.value, 10))}
                                        >
                                            {thresholdFilters.map((filter) => {
                                                return (
                                                    <FormControlLabel
                                                        key={filter.id}
                                                        value={filter.id}
                                                        control={<Radio />}
                                                        label={filter.label}
                                                    />
                                                )
                                            })}
                                        </RadioGroup>
                                    </div>
                                    <div className="input-rows checkbox-padding-removal">
                                        <CheckboxInput
                                            label={t('fields.showOnlyNotClosedEvents')}
                                            value={searchThroughOpenAlarms}
                                            onChangeValue={(value: boolean) => setSearchThroughOpenAlarms(value)}
                                        />
                                    </div>
                                </div>
                                <div className="filter-column">
                                    <h3>{t('fields.searchInterval')}</h3>
                                    <div className="calendar-selection-container">
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectStartOfInterval')}
                                            onDateChange={(newDate) => setFilterStartDate(newDate)}
                                        />
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectEndOfInterval')}
                                            onDateChange={(newDate) => setFilterEndDate(newDate)}
                                            minSelectableDate={filterStartDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-wrapper-action-section">
                                <button
                                    disabled={filterParameters === null}
                                    className="filter-secondary-button"
                                    onClick={() => resetFilterParameters()}
                                >
                                    {t('common.reset')}
                                </button>
                                <button
                                    disabled={!filterParametersChanged}
                                    className="standard-confirm-button"
                                    onClick={() => getDetailedFilteredEvents()}
                                >
                                    {t('common.search')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <EventsHistoryTable reloads={toggleReset} filterParams={filterParameters} />
                <Footer />
            </div>
        </PageContainer>
    )
}

export default EventsHistory
