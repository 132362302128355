import React, { useMemo, useState } from 'react'
import Button from '../../Components/Inputs/Button'
import Footer from '../../Components/StaticComponents/Footer'
import '../../styles/pages/main.sass'
import { useDispatch, useSelector } from 'react-redux'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useEffect } from 'react'
import { getLoggedUserInfo } from '../../store/actions/requests'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import TextInput from '../../Components/Inputs/TextInput'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import { faArrowLeft, faCancel, faCheck, faCircleInfo, faPen } from '@fortawesome/free-solid-svg-icons'
import AlertModal from '../../Components/Modals/AlertModal'
import { ROUTE_NAMES } from '../../utils/routes'
import { useNavigate } from 'react-router-dom'
import CustomAxios from '../../customComponents/customAxios'
import PageHeaderCustom from '../../customComponents/PageHeaderCustom'
import { useTranslation } from 'react-i18next'
import TwoFactorAuth from '../../Components/TwoFactorAuth'
import { setTokenData } from '../../store/actions/user'
import { Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from 'antd'
import { COLORS } from '../../styles/constants'

const PersonalProfilePage: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [userLanguage, setUserLanguage] = useState<SelectOptionValue | null>({
        label: t('fields.languages'),
        value: '',
    })
    const [isPreviewMode, setIsPreviewMode] = useState(true)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [showPassError, setShowPassError] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [username, setUsername] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const navigate = useNavigate()
    const [twoFactorData, setTwoFactorData] = useState<any | undefined>()

    const availableLanguages = [
        {
            id: 'it-IT',
            label: 'Italiano',
        },
        {
            id: 'en-GB',
            label: 'Inglese',
        },
        {
            id: 'uk-UA',
            label: 'Українська',
        }
    ]

    const setUserInfo = () => {
        setUsername(user.username)
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setEmail(user.email)
        setPhone(user.phone)
    }

    const generateQrCode = async () => {
        try {
            const response = await CustomAxios.post('/auth/generate-otp/', { user_id: user.ID }).then(
                (response) => response.data
            )

            if (response) {
                setTwoFactorData({ base32: response.base32, otpauth_url: response.otpauthUrl })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const disableTwoFactorAuth = async () => {
        try {
            const response = await CustomAxios.post('/auth/disable-otp/', { user_id: user.ID }).then(
                (response) => response.data
            )

            if (response) {
                dispatch(setTokenData(response))
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    useEffect(() => {
        dispatch(getLoggedUserInfo())
        const language = availableLanguages.find((language) => language.id === user.language) || {
            id: 'EN',
            label: 'English',
        }
        setUserLanguage({ label: language.label, value: language.id })
        setUserInfo()
    }, [])

    const saveUserData = async () => {
        try {
            const userData = {
                lastName,
                firstName,
                email,
                username,
                phone,
                language: userLanguage.value,
            }
            await CustomAxios.put(`users/user/${user.ID}`, userData)
            await dispatch(getLoggedUserInfo())

            setIsPreviewMode(true)
        } catch (error) {
            console.error(error)
        }
    }

    const updatePassword = async () => {
        try {
            setShowPassError(false)
            const passwordData = {
                oldPassword,
                newPassword,
                confirmNewPassword: newPassword,
            }
            await CustomAxios.put('auth/update_password/', passwordData)
            setOldPassword('')
            setNewPassword('')
        } catch (error) {
            console.error(error)
            setShowPassError(true)
        }
    }

    const deviceDataIsCorrect = useMemo(() => {
        return username !== '' && firstName !== '' && lastName !== '' && email !== ''
    }, [username, firstName, lastName, email])

    const passwordDataIsCorrect = useMemo(() => {
        return oldPassword !== '' && newPassword !== ''
    }, [oldPassword, newPassword])

    return (
        <PageContainer>
            {twoFactorData && (
                <TwoFactorAuth
                    otpauth_url={twoFactorData.otpauth_url}
                    base32={twoFactorData.base32}
                    user_id={user.ID.toString()}
                    closeModal={() => {
                        setTwoFactorData(undefined)
                    }}
                />
            )}
            {showAlertModal && (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => navigate(`${ROUTE_NAMES.TODAY_ALARM}?page=0`)}
                    onClose={() => setShowAlertModal(false)}
                />
            )}
            <div className="headerSection addNewSection">
                <PageHeaderCustom titleKey="routes.profile" descriptionKey="pageDescription.profile" />
                <div className="header-right-actions">
                    {isPreviewMode ? (
                        <Button
                            label={t('common.edit')}
                            backgroundColor="#e10915"
                            textColor="#ffffff"
                            margin="0 0 0 25px"
                            iconColor="#ffffff"
                            icon={faPen}
                            onPress={() => setIsPreviewMode(false)}
                        />
                    ) : (
                        <>
                            <Button
                                label={t('common.cancel')}
                                backgroundColor="#efefef"
                                textColor="#3f3f3f"
                                margin="0 0 0 25px"
                                icon={faArrowLeft}
                                onPress={() => setShowAlertModal(true)}
                            />
                            <Button
                                isDisabled={!deviceDataIsCorrect}
                                label={t('common.save')}
                                backgroundColor="#e10915"
                                textColor="#ffffff"
                                margin="0 0 0 25px"
                                iconColor="#ffffff"
                                icon={faPen}
                                onPress={() => void saveUserData()}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="body">
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: 10,
                        }}
                    >
                        <div style={{ marginTop: '1%', display: 'flex', gap: 12 }}>
                            <h3>{t('fields.twoFactor')}</h3>
                            <Tooltip
                                title={<div style={{ whiteSpace: 'pre-line' }}>{t('fields.twoFactorHint')}</div>}
                                style={{ paddingBottom: 4 }}
                            >
                                <div>
                                    <FontAwesomeIcon icon={faCircleInfo} color={COLORS.palette.darkGrey} />
                                </div>
                            </Tooltip>
                        </div>

                        {user.otpInfo !== null && user.otpInfo.otpEnabled ? (
                            <Button
                                isDisabled={isPreviewMode}
                                label={t('fields.disable')}
                                backgroundColor="#e10915"
                                textColor="#ffffff"
                                iconColor="#ffffff"
                                icon={faCancel}
                                onPress={() => void disableTwoFactorAuth()}
                            />
                        ) : (
                            <Button
                                isDisabled={isPreviewMode}
                                label={t('fields.enable')}
                                backgroundColor="#e10915"
                                textColor="#ffffff"
                                iconColor="#ffffff"
                                icon={faCheck}
                                onPress={() => void generateQrCode()}
                            />
                        )}
                        <Divider />
                        <div className="input-row spaced-inputs">
                            <TextInput
                                label={t('fields.name')}
                                value={firstName}
                                onChangeText={(value) => setFirstName(value)}
                                onlyVisible={isPreviewMode}
                            />
                            <TextInput
                                label={t('fields.lastName')}
                                value={lastName}
                                onChangeText={(value) => setLastName(value)}
                                onlyVisible={isPreviewMode}
                            />
                        </div>
                        <div className="input-row spaced-inputs">
                            <TextInput
                                label={t('fields.username')}
                                value={username}
                                onChangeText={(value) => setUsername(value)}
                                onlyVisible
                            />
                            <TextInput
                                label={t('fields.email')}
                                value={email}
                                onChangeText={(value) => setEmail(value)}
                                onlyVisible={isPreviewMode}
                            />
                        </div>
                        <TextInput
                            label={t('fields.phoneNumber')}
                            value={phone}
                            onChangeText={(value) => setPhone(value)}
                            onlyVisible={isPreviewMode}
                        />
                        <Divider />
                        <h3>{t('fields.userLocale')}</h3>
                        <SelectInput
                            isSmall
                            disabled={isPreviewMode}
                            value={userLanguage}
                            onValueChange={(value) => setUserLanguage(value)}
                            options={availableLanguages.map((language) => {
                                return { label: language.label, value: language.id }
                            })}
                            width={'30%'}
                        />
                        <Divider />
                        <h3>{t('fields.changePassword')}</h3>
                        {showPassError && (
                            <p className="error-message">{t('systemMessages.oldPasswordNotCorrectMessage')}</p>
                        )}
                        <div className="input-row spaced-inputs">
                            <TextInput
                                label={t('fields.oldPassword')}
                                placeholder={t('fields.oldPassword')}
                                value={oldPassword}
                                onChangeText={(value) => setOldPassword(value)}
                                onlyVisible={isPreviewMode}
                            />
                            <TextInput
                                label={t('fields.newPassword')}
                                placeholder={t('fields.newPassword')}
                                value={newPassword}
                                onChangeText={(value) => setNewPassword(value)}
                                onlyVisible={isPreviewMode}
                            />
                            <div className="update-pass-button">
                                <Button
                                    isDisabled={!passwordDataIsCorrect}
                                    label={t('common.update')}
                                    backgroundColor="#e10915"
                                    textColor="#ffffff"
                                    iconColor="#ffffff"
                                    icon={faPen}
                                    onPress={() => void updatePassword()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </PageContainer>
    )
}

export default PersonalProfilePage
