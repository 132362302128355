import { faToolbox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import '../../Components/CustomProj4'

import Footer from '../../Components/StaticComponents/Footer'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import '../../styles/pages/main.sass'
import '../../styles/pages/alarms.sass'
import 'leaflet/dist/leaflet.css'

import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import CustomAxios from '../../customComponents/customAxios'
import { getDeviceEvents, getDevices } from '../../utils/api-constants'
import GeneratedMap from '../../Components/LeafletMap'
import PageHeaderCustom from '../../customComponents/PageHeaderCustom'
import SelectInput from '../../Components/Inputs/SelectInput'
import Loader from '../../Components/StaticComponents/Loader'
import MapDevicesList from '../../Components/Tables/MapDevicesList'
import Timer from '../../Components/StaticComponents/Timer'
import { MapDeviceItem } from '../../types/data/map'
import { DeviceEventSearchResponse } from '../../types/data/system'
import { COLORS, DEVICE_STATUS_CODES } from '../../styles/constants'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers } from '../../types/reducers'

const MapPage: React.FC = () => {
    const { currentNetwork } = useSelector<Reducers, ReducerData>((store) => store.data)
    const [selectedMapOption, setSelectedMapOption] = useState('all')
    const [clusterDevicesList, setClusterDevicesList] = useState<any[]>([])
    const [mapEvents, setMapEvents] = useState([])
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        void getEventsFromDB()
    }, [currentNetwork])

    useEffect(() => {
        return () => {
            localStorage.removeItem('alarm_map_center')
            localStorage.removeItem('alarm_map_zoom')
        }
    }, [])

    const mapFilterOptions = useMemo(() => {
        return [
            {
                label: t('mapPageFilters.showAllDevices'),
                value: 'all',
            },
            {
                label: t('mapPageFilters.showOnlyAlarms'),
                value: 'onlyAlarms',
            },
            {
                label: t('mapPageFilters.showOnlyDisconnected'),
                value: 'onlyDisconnected',
            },
            {
                label: t('mapPageFilters.showOnlyOnMaintenanceMode'),
                value: 'onlyOnMaintenanceMode',
            },
        ]
    }, [])

    const getEventsFromDB = useCallback(async () => {
        let deviceAlarms = []
        let formattedMapAlarms = []
        setMapEvents(formattedMapAlarms)
        setLoading(true)
        setClusterDevicesList([])
        const devices = await CustomAxios.get(getDevices()).then((response) => response.data.data)
        let networkFiltered = devices
        if (currentNetwork !== undefined) {
            networkFiltered = devices.filter((device) => device.idNetwork === currentNetwork.ID)
        }

        deviceAlarms = await CustomAxios.get(`${getDeviceEvents()}?map=true&hidden=false`).then(
            (response) => response.data.data
        )
        setLoading(false)
        formattedMapAlarms = networkFiltered
            .filter((device) => device.automaticCheck)
            .map((device) => {
                const events: DeviceEventSearchResponse = deviceAlarms.find((event) => event.device.id === device.ID)
                if (device.coordinates && device.coordinates !== null) {
                    const item: MapDeviceItem = {
                        deviceId: device.ID,
                        name: device.name,
                        lat: device.coordinates.latitude,
                        lon: device.coordinates.longitude,
                        deviceStatus: device.currentState,
                        maintenanceMode: device.maintenanceMode,
                        automaticCheck: device.automaticCheck,
                        maxAlarmLevel:
                            events && events
                                ? events.openedEvents && events.openedEvents.length
                                    ? Math.max(...events.openedEvents.map((openEvent) => openEvent.level))
                                    : 0
                                : undefined,
                        openedEvents: events && events.openedEvents ? events.openedEvents : [],
                        onSelect: () => {
                            navigate(`/${ROUTE_NAMES.DEVICE}/${device.ID}`, {
                                state: { deviceId: device.ID, isView: true },
                            })
                        },
                    }

                    return item
                }
                return
            })
            .filter((mapAlarm) => mapAlarm)
            .filter(
                (deviceInMap) =>
                    (selectedMapOption === 'onlyDisconnected' &&
                        deviceInMap.deviceStatus === DEVICE_STATUS_CODES.ureachable) ||
                    (selectedMapOption === 'onlyOnMaintenanceMode' && deviceInMap.maintenanceMode === 1) ||
                    (selectedMapOption === 'onlyAlarms' && deviceInMap.maxAlarmLevel !== undefined) ||
                    selectedMapOption === 'all'
            )

        setMapEvents(formattedMapAlarms)
    }, [selectedMapOption, currentNetwork])

    useEffect(() => {
        if (selectedMapOption === '') return
        void getEventsFromDB()
    }, [selectedMapOption])

    return (
        <PageContainer>
            <div className="headerSection">
                <PageHeaderCustom titleKey="routes.map" descriptionKey="pageDescription.eventsMap" />
            </div>
            <div className="sectionContent">
                <div className="introTodayAlarms">
                    <div style={{ zIndex: 99 }}>
                        <SelectInput
                            width={'270px'}
                            options={mapFilterOptions.map((opt) => {
                                return { value: opt.value, label: opt.label }
                            })}
                            value={
                                mapFilterOptions.find((opt) => opt.value === selectedMapOption) || {
                                    value: '',
                                    label: '',
                                }
                            }
                            onValueChange={(v) => {
                                setSelectedMapOption(v.value)
                            }}
                        />
                    </div>
                    <div>
                        <Timer count={mapEvents.length} time={59} callback={getEventsFromDB} />
                    </div>
                </div>
                <div className="main-map-wrapper">
                    <div className="main-map-container">
                        {loading && <Loader isAbsolutePos zIndex={999} />}
                        <GeneratedMap
                            mapEvents={mapEvents}
                            saveDevicesListElements={(elements) => {
                                setClusterDevicesList(elements)
                            }}
                        />
                    </div>
                    <MapDevicesList items={clusterDevicesList} />
                </div>
                <div style={{ marginTop: '20px' }}>
                    {t('fields.mapColorCodes')}
                    <div className="mapColorCodesSection">
                        <div className="mapColorCodesCircle" style={{ backgroundColor: COLORS.events.high }} />
                        {t('fields.eventHightPriorityDesc')}
                        <div className="mapColorCodesCircle" style={{ backgroundColor: COLORS.events.medium }} />
                        {t('fields.eventMediumLowPriorityDesc')}
                        <div className="mapColorCodesCircle" style={{ backgroundColor: COLORS.palette.green }} />
                        {t('fields.eventNoneDesc')}
                        <div
                            className="mapColorCodesCircle"
                            style={{ backgroundColor: COLORS.deviceStatus.unreachable }}
                        />
                        {t('fields.eventNotConnectedDesc')}
                        <div className="mapColorCodesCircle">
                            <FontAwesomeIcon
                                icon={faToolbox}
                                color={COLORS.palette.darkBlue}
                                style={{ width: '20px', height: '20px' }}
                            />
                        </div>

                        {t('fields.eventMaintenanceDesc')}
                    </div>
                </div>
            </div>
            <Footer />
        </PageContainer>
    )
}

export default MapPage
