import React from 'react'
import Select, { SingleValue, MultiValue } from 'react-select'
import '../../styles/pages/main.sass'

export interface SelectOptionValue {
    label: string | JSX.Element
    value: string
}

interface Props {
    disabled?: boolean
    required?: boolean
    isSmall?: boolean
    isMulti?: boolean
    label?: string
    styles?: string[]
    placeholder?: string
    options: SelectOptionValue[]
    value: SelectOptionValue | SelectOptionValue[] | null
    onValueChange?: (value: SelectOptionValue | null) => void
    onMultiValueChange?: (value: SelectOptionValue[]) => void
    width?: number | string
}

const SelectInput: React.FC<Props> = (props) => {
    const handleChange = (
        option: SingleValue<SelectOptionValue> | MultiValue<SelectOptionValue> | null
    ) => {
        if (props.isMulti) {
            props.onMultiValueChange(option as SelectOptionValue[]);
        } else {
            props.onValueChange(option as SelectOptionValue | null);
        }
    };

    return (
        <div
            style={{ minWidth: props.isSmall && props.width ? props.width : '125px' }}
            className={`selectedInput ${props.styles !== undefined ? props.styles.join(' ') : ''}`}
        >
            {props.label && (
                <div className = "labelInput label-input-no-margin">
                    {props.label}
                    {props.required && <label className="required-asterisk">*</label>}
                </div>
            )}
            <div className="selectFieldContainer">
                <Select
                    isMulti={props.isMulti}
                    isDisabled={props.disabled}
                    className="selectFieldInput"
                    options={props.options}
                    placeholder={props.placeholder || ''}
                    value={props.value}
                    onChange={handleChange}
                    styles={{
                        container: (styles) => {
                            return {
                                ...styles,
                                outline: 0,
                                width: props.width ? props.width : 200,
                                minWidth: props.isSmall && props.width ? props.width : 'inherit',
                            }
                        },
                        control: (styles) => {
                            return {
                                ...styles,
                                borderWidth: 0,
                                boxShadow: 'none',
                                border: '1px solid rgba(0, 0, 0, .24)',
                                borderRadius: '6px',
                                cursor: 'pointer',
                            }
                        },
                        indicatorSeparator: (styles) => {
                            return {
                                ...styles,
                                display: 'none',
                            }
                        },
                        dropdownIndicator: (styles) => {
                            return {
                                ...styles,
                                color: '#e10915',
                                cursor: 'pointer',
                            }
                        },
                        option: (styles, { isFocused }) => {
                            return {
                                ...styles,
                                color: isFocused ? '#E10915' : '#3f3f3f;',
                                backgroundColor: isFocused ? '#efefef' : '#fff',
                                fontSize: 13,
                                cursor: 'pointer',
                            }
                        },
                        input: (styles) => {
                            return {
                                ...styles,
                                borderWidth: 0,
                                outline: 0,
                                cursor: 'pointer',
                            }
                        },
                        singleValue: (styles) => {
                            return {
                                ...styles,
                                fontSize: 13,
                                cursor: 'pointer',
                            }
                        },
                        placeholder: (styles) => {
                            return {
                                ...styles,
                                fontSize: 13,
                                color: '#3f3f3f',
                            }
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default SelectInput
