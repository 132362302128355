import { faPlus, faRedo, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomAxios from '../../customComponents/customAxios'
import { AvailableCommand, NetworkGroup } from '../../types/data/system'
import { User } from '../../types/data/user'
import { Reducers, ReducerUser } from '../../types/reducers'
import { deepCopy } from '../../utils/functions'
import { ROUTE_NAMES } from '../../utils/routes'
import Datepicker from '../Datepicker'
import Button from '../Inputs/Button'
import CheckboxInput from '../Inputs/CheckboxInput'
import SelectInput, { SelectOptionValue } from '../Inputs/SelectInput'
import GroupSchedulersTable from '../Tables/GroupSchedulersTable'
import { useTranslation } from 'react-i18next'

interface Props {
    currentGroup: NetworkGroup
    viewOnly?: boolean
}

const GroupSchedulersTab: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const [filterPanelIsVisible, setFilterPanelIsVisible] = useState(false)
    const { t } = useTranslation()
    const [schedulerGroup, setSchedulerGroup] = useState<SelectOptionValue | null>({
        label: t('fields.group'),
        value: '',
    })
    const [schedulerDevice, setSchedulerDevice] = useState<SelectOptionValue | null>({
        label: t('fields.device'),
        value: '',
    })
    const [schedulerCreator, setSchedulerCreator] = useState<SelectOptionValue | null>({
        label: t('fields.user'),
        value: '',
    })
    const [schedulerFilterCommand, setSchedulerFilterCommand] = useState<SelectOptionValue | null>({
        label: t('fields.command'),
        value: '',
    })
    const [availableCommands, setAvailableCommands] = useState<AvailableCommand[]>([])
    const [availableUsers, setAvailableUsers] = useState<User[]>([])
    const [toggleReset, setToggleReset] = useState(undefined)
    const [searchThroughEnabledSchedulers, setSearchThroughEnabledSchedulers] = useState(false)
    const [filterParameters, setFilterParameters] = useState<any | null>(null)
    const navigate = useNavigate()

    const [filterStartDate, setFilterStartDate] = useState('')
    const [filterEndDate, setFilterEndDate] = useState('')

    const getFilterParameters = () => {
        const filterParameters: any = {
            device: schedulerDevice.value === '' ? '' : parseInt(schedulerDevice.value, 10),
            command: schedulerFilterCommand.value === '' ? '' : parseInt(schedulerFilterCommand.value, 10),
            from: filterStartDate,
            to: filterEndDate,
            enabled: searchThroughEnabledSchedulers ? 1 : undefined,
            user: schedulerCreator.value === '' ? -1 : parseInt(schedulerCreator.value, 10),
            group: props.currentGroup.id,
        }
        Object.keys(filterParameters).forEach((key) => {
            const attributeValue = filterParameters[key]
            if (
                attributeValue === '' ||
                attributeValue === null ||
                attributeValue === -1 ||
                attributeValue === undefined
            ) {
                delete filterParameters[key]
            }
        })
        if (Object.keys(filterParameters).length > 0) {
            return { ...filterParameters }
        }
        return null
    }

    const filterParametersChanged = useMemo(() => {
        return (
            schedulerFilterCommand.value !== '' ||
            filterEndDate !== '' ||
            filterStartDate !== '' ||
            searchThroughEnabledSchedulers ||
            schedulerCreator.value !== '' ||
            schedulerGroup.value !== '' ||
            schedulerDevice.value !== ''
        )
    }, [
        schedulerFilterCommand,
        filterStartDate,
        filterEndDate,
        searchThroughEnabledSchedulers,
        schedulerCreator,
        schedulerGroup,
        schedulerDevice,
    ])

    const getFilteredDevices = () => {
        setFilterParameters(getFilterParameters())
    }

    const getAllUsers = async () => {
        try {
            const users: User[] = await CustomAxios.get('users').then((response) => response.data.data)
            setAvailableUsers(users)
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableCommands = async () => {
        try {
            const commands: AvailableCommand[] = await CustomAxios.get(
                `available-commands?group=${props.currentGroup.id}`
            ).then((response) => response.data.data)
            if (commands.length > 0) {
                setAvailableCommands(commands)
            } else {
                setAvailableCommands([])
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void getAllUsers()
        void getAvailableCommands()
    }, [])

    const resetFilterParameters = () => {
        setFilterStartDate('')
        setFilterEndDate('')
        setSchedulerGroup({ label: t('fields.group'), value: '' })
        setSchedulerCreator({ label: t('fields.user'), value: '' })
        setSchedulerFilterCommand({ label: t('fields.command'), value: '' })
        setSchedulerDevice({ label: t('fields.device'), value: '' })
        setSearchThroughEnabledSchedulers(false)
        setAvailableCommands([])
        setFilterParameters(props.currentGroup !== undefined ? { group: props.currentGroup.id } : null)
        setToggleReset(!toggleReset)
    }

    const availableCommandSelect = useMemo(() => {
        let commandToDisplay = deepCopy(availableCommands)
        commandToDisplay = commandToDisplay.filter(
            (command: AvailableCommand) => command.requiredGrant === user.idSysGrant
        )
        return (
            <SelectInput
                disabled={schedulerDevice.value === '' && schedulerGroup.value === ''}
                value={schedulerFilterCommand}
                onValueChange={(value) => setSchedulerFilterCommand(value)}
                options={commandToDisplay.map((command: AvailableCommand) => {
                    return { label: command.name, value: command.command }
                })}
                width={'100%'}
            />
        )
    }, [availableCommands, user, schedulerFilterCommand, schedulerDevice, schedulerGroup])

    return (
        <div style={{ margin: '30px 0' }}>
            <div style={{ margin: 20 }}>
                <div>
                    <div className="header-right-actions">
                        <Button
                            label={t('fields.filterScheduler')}
                            backgroundColor="#efefef"
                            textColor="#3f3f3f"
                            margin="0 25px 0 0"
                            borderColor={filterPanelIsVisible ? '#212121' : undefined}
                            icon={faSlidersH}
                            onPress={() => setFilterPanelIsVisible(!filterPanelIsVisible)}
                        />
                        <Button
                            label={t('common.update')}
                            backgroundColor="#efefef"
                            textColor="#3f3f3f"
                            margin={
                                user.idSysGrant === 0 ||
                                user.managedNetworks.find((networkId) => networkId === props.currentGroup.idNetwork)
                                    ? '0 25px 0 0'
                                    : '0'
                            }
                            icon={faRedo}
                            onPress={() => setToggleReset(!toggleReset)}
                        />
                        {(user.idSysGrant === 0 ||
                            user.managedNetworks.find((networkId) => networkId === props.currentGroup.idNetwork)) && (
                            <Button
                                label={t('common.addScheduler')}
                                backgroundColor="#e10915"
                                textColor="#fff"
                                icon={faPlus}
                                iconColor="#fff"
                                onPress={() =>
                                    navigate(`/${ROUTE_NAMES.PLANNED_COMMANDS}/new-scheduler`, {
                                        state: { groups: props.currentGroup.id },
                                    })
                                }
                                margin="0"
                            />
                        )}
                    </div>
                    <div>
                        {filterPanelIsVisible && (
                            <div className="advanced-search-wrapper padding-search-wrapper">
                                <div className="search-wrapper-body">
                                    <div className="filter-column">
                                        <h3>{t('fields.schedulerInfo')}</h3>
                                        {availableCommandSelect}
                                    </div>
                                    <div className="filter-column column-without-title">
                                        {availableUsers.length > 0 && (
                                            <SelectInput
                                                value={schedulerCreator}
                                                onValueChange={(value) => setSchedulerCreator(value)}
                                                options={availableUsers.map((user: User) => {
                                                    return {
                                                        label: `${user.firstName} ${user.lastName}`,
                                                        value: user.ID.toString(),
                                                    }
                                                })}
                                                width={'100%'}
                                            />
                                        )}
                                        <CheckboxInput
                                            label={t('fields.showOnlyEnabledSchedulers')}
                                            value={searchThroughEnabledSchedulers}
                                            onChangeValue={(value: boolean) => setSearchThroughEnabledSchedulers(value)}
                                        />
                                    </div>
                                    <div className="filter-column">
                                        <h3>{t('fields.searchInterval')}</h3>
                                        <div className="calendar-selection-container">
                                            <Datepicker
                                                reset={toggleReset}
                                                initialValue={t('fields.selectStartOfInterval')}
                                                onDateChange={(newDate) => setFilterStartDate(newDate)}
                                            />
                                            <Datepicker
                                                reset={toggleReset}
                                                initialValue={t('fields.selectEndOfInterval')}
                                                onDateChange={(newDate) => setFilterEndDate(newDate)}
                                                minSelectableDate={filterStartDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-wrapper-action-section">
                                    <button
                                        disabled={filterParameters === null}
                                        className="filter-secondary-button"
                                        onClick={() => resetFilterParameters()}
                                    >
                                        {t('common.reset')}
                                    </button>
                                    <button
                                        disabled={!filterParametersChanged}
                                        className="standard-confirm-button"
                                        onClick={() => getFilteredDevices()}
                                    >
                                        {t('common.search')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <GroupSchedulersTable
                viewOnly={props.viewOnly}
                filterParams={filterParameters}
                currentGroup={props.currentGroup}
            />
        </div>
    )
}

export default GroupSchedulersTab
